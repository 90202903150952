import { GetStaticProps } from "next"

import Hero from "containers/Homepage/Hero"
import About from "containers/Homepage/About"
import CategorySection from "containers/Homepage/CategorySection"
import Explore from "containers/Homepage/Explore"
import CategoryGrid from "containers/Homepage/CategoryGrid"

import { anonymousAccessTokenCache } from "@secureo/common/utils/commerceTools/token/accessTokenCache"
import fetchCategory, {
	fetchCategorySeries,
	SeriesPreview,
} from "utils/commerceTools/fetchCategory"

import categories from "config/navCategories.json"

import { Category } from "@secureo/common/typings/Category"
import { NavCategory } from "utils/commerceTools/graphql/queries/fetchNavCategories"

const highlightedCategorySlugs = [
	"wertschutzschraenke",
	"langwaffenschraenke",
	"feuerschutztresore",
]
const gridCategorySlugs = ["feuerschutztresore", "wandtresore", "hotelsafes"]

import WertschutzschraenkeBG from "/images/wertschutzschränke.png"
import WaffenschraenkeBG from "/images/waffenschränke.png"

const HomePage = ({ highlightedCategories, gridCategories }: Props) => {
	return (
		<>
			<Hero />
			<About />
			<CategorySection
				category={highlightedCategories?.[0]}
				backgroundImage={WertschutzschraenkeBG}
			/>
			<Explore categories={gridCategories} />
			<CategorySection
				category={highlightedCategories?.[1]}
				backgroundImage={WaffenschraenkeBG}
			/>
			<CategoryGrid categories={categories as NavCategory[]} />
		</>
	)
}

export const getStaticProps: GetStaticProps = async (context) => {
	const accessToken = await anonymousAccessTokenCache()
	const locale = context.locale as string

	const highlightedCategories = await Promise.all(
		highlightedCategorySlugs.map((slug) => fetchCategory(accessToken, slug, locale)),
	)
	const gridCategories = await Promise.all(
		gridCategorySlugs.map((slug) => fetchCategory(accessToken, slug, locale)),
	)
	const series = await Promise.all(
		highlightedCategories.map((category) => fetchCategorySeries(accessToken, category.id)),
	)

	for (let i = 0; i < highlightedCategories.length; i++) {
		const category = highlightedCategories[i] as Category & { series?: SeriesPreview[] }
		category.series = series[i]
	}

	const props: Props = {
		locale,
		highlightedCategories,
		gridCategories,
	}

	return { props }
}

interface Props {
	locale: string
	highlightedCategories: (Category & { series?: SeriesPreview[] })[]
	gridCategories: Category[]
}

export default HomePage
