import { Container, Box, Grid } from "@mui/material"
import { makeStyles } from "makeStyles"
import Image from "next/image"

import Link from "components/i18n/Link"

import { NavCategory } from "utils/commerceTools/graphql/queries/fetchNavCategories"

const useStyles = makeStyles()(() => ({
	root: {
		textAlign: "center",
		background: "#182F37",
		padding: "70px 0px",
		color: "white",
	},
	card: {
		background: "rgba(255, 255, 255, 0.1)",
		borderRadius: 3,
		padding: "40px 0px",
		width: "100%",
		wordBreak: "break-all",
		transition: "0.3s",
		letterSpacing: 0.7,
		"&:hover": {
			background: "rgba(0, 156, 172, 0.5)",
			letterSpacing: 0.9,
		},
	},
	fontSize: {
		fontSize: "1rem",
		"@media only screen and (max-width: 960px)": {
			fontSize: "0.7rem",
		},
	},
}))

const CategoryGrid = ({ categories }: Props) => {
	const { classes } = useStyles()

	return (
		<div className={classes.root}>
			<h2 style={{ margin: 0 }}>Alle Kategorien durchstöbern</h2>
			<Box my={5}>
				<Container>
					<Grid container spacing={3}>
						{categories.map((category) => {
							const { name, slug, icon } = category

							return (
								<Grid item xs={6} md={3} key={slug}>
									<Link href={`/c/${slug}`}>
										<div className={classes.card}>
											<Image
												src={icon}
												alt={name}
												width={60}
												height={70}
												style={{
													objectFit: "contain",
												}}
											/>
											<div
												className={classes.fontSize}
												style={{ marginTop: "1rem" }}
											>
												{name}
											</div>
										</div>
									</Link>
								</Grid>
							)
						})}
					</Grid>
				</Container>
			</Box>
		</div>
	)
}

interface Props {
	categories: NavCategory[]
}

export default CategoryGrid
