import { Container, Grid, Box, IconButton } from "@mui/material"
import { makeStyles } from "makeStyles"
import { Grow, Slide } from "@mui/material"
import Link from "components/i18n/Link"
import Image from "next/image"
import HeroImage from "/images/startseite-hero.png"

import { FaCogs } from "react-icons/fa"
import { AiFillFilePdf } from "react-icons/ai"
import { RiSafe2Line } from "react-icons/ri"
import { BiStoreAlt } from "react-icons/bi"

import { FaFacebook, FaXingSquare } from "react-icons/fa"
import { AiOutlineInstagram, AiFillLinkedin, AiFillTwitterCircle } from "react-icons/ai"

import { socialMediaPages } from "config/contact"

const useStyles = makeStyles()(() => ({
	responsiveHeadline: {
		marginTop: 0,
		maxWidth: 800,
		lineHeight: 1.3,
		fontSize: 40,
		textShadow: "1px 1px 2px rgba(0, 0, 0, 0.4)",
		"@media only screen and (max-width: 960px)": {
			fontSize: 30,
			marginTop: 10,
		},
	},
	textBox: {
		color: "white",
		padding: "100px 0px 20px 0px",
		"@media only screen and (max-width: 960px)": {
			padding: "40px 0px 20px 0px",
		},
	},
	header: {
		minHeight: "750px",
		display: "flex",
		color: "white",
	},
	headerBgImage: {
		position: "absolute",
		background: "#042C33",
		top: 0,
		left: 0,
		height: "850px",
		width: "100%",
		overflow: "hidden",
		zIndex: -1,
		"@media only screen and (max-width: 960px)": {
			filter: "brightness(50%)",
		},
	},
	responsiveSocialIcons: {
		display: "block",
		position: "absolute",
		bottom: 170,
		left: 20,
		"@media only screen and (max-width: 960px)": {
			display: "none",
		},
	},
	card: {
		height: 190,
		width: "100%",
		borderRadius: 5,
		margin: 0,
		color: "white",
		background: "#042C33",
		boxShadow: "0 10px 12px rgba(0, 0, 0, 0.10)",
		transition: "0.5s",
		"&:hover": {
			boxShadow: "0 10px 12px rgba(0, 0, 0, 0.30)",
			cursor: "pointer",
		},
		"@media only screen and (max-width: 960px)": {
			display: "flex",
			alignItems: "center",
			height: 60,
		},
	},
	cardDescription: {
		opacity: 0.8,
		fontSize: "0.9rem",
		display: "block",
		"@media only screen and (max-width: 960px)": {
			display: "none",
		},
	},
	flexItems: {
		display: "block",
		padding: 20,
		"@media only screen and (max-width: 960px)": {
			display: "flex",
			alignItems: "center",
		},
	},
	fontSize: {
		fontSize: "1rem",
		"@media only screen and (max-width: 960px)": {
			fontSize: "0.9rem",
		},
	},
}))

const cards = [
	{
		icon: <FaCogs size="2vh" color="#fff" />,
		headline: "Ersatzteile bestellen",
		text: "Bestellen Sie ein Ersatzteil für Ihr Produkt nach",
		linkTo: "/spare-parts",
	},
	{
		icon: <AiFillFilePdf size="2vh" color="#fff" />,
		headline: "Bedienungsanleitungen",
		text: "Laden Sie sich Ihre Bedienungsanleitung einfach herunter",
		linkTo: "/manuals",
	},
	{
		icon: <RiSafe2Line size="2vh" color="#fff" />,
		headline: "Produktregistrierung",
		text: "Registrieren Sie Ihr Produkt schnell und einfach online",
		linkTo: "/register-products",
	},
	{
		icon: <BiStoreAlt size="2vh" color="#fff" />,
		headline: "Branchenlösungen",
		text: "Das passende Produkt für Ihre gewerbliche Tätigkeit",
		linkTo: "/solutions",
	},
]

const Hero = () => {
	const { classes } = useStyles()

	return (
		<div style={{ overflow: "hidden", position: "relative" }}>
			<div className={classes.header}>
				<div className={classes.headerBgImage}>
					<Image
						alt="Kontakt"
						src={HeroImage}
						quality={70}
						fill
						sizes="100vw"
						style={{
							objectFit: "cover",
						}}
					/>
				</div>
				<Container style={{ position: "relative" }}>
					<Box className={classes.textBox}>
						<p
							style={{
								color: "#009CAC",
								marginBottom: 0,
								fontSize: 18,
								textShadow: "1px 1px 2px rgba(0, 0, 0, 0.4)",
							}}
						>
							<strong>Tresore &amp; Waffenschränke</strong>
						</p>
						<h1 className={classes.responsiveHeadline}>
							Hochwertige Sicherheitsprodukte zu äußerst attraktiven Preisen
						</h1>
						<p
							style={{
								maxWidth: 500,
								lineHeight: 1.3,
								textShadow: "1px 1px 2px rgba(0, 0, 0, 0.4)",
							}}
						>
							Geprüfte und zertifizierte Tresore sowie Waffenschränke
						</p>
					</Box>
					<Grow in timeout={{ enter: 800 }}>
						<Grid container spacing={3}>
							{cards.map((cards) => {
								return (
									<Grid item xs={12} sm={6} md={3} key={cards.headline}>
										<Link href={cards.linkTo}>
											<div className={classes.card}>
												<div className={classes.flexItems}>
													<IconButton
														style={{
															background: "#009CAC",
															padding: 9,
															marginRight: 15,
														}}
														size="large"
													>
														{cards.icon}
													</IconButton>
													<Box my={2}>
														<strong className={classes.fontSize}>
															{cards.headline}
														</strong>
														<div className={classes.cardDescription}>
															{cards.text}
														</div>
													</Box>
												</div>
											</div>
										</Link>
									</Grid>
								)
							})}
						</Grid>
					</Grow>
				</Container>
			</div>
			<Slide direction="right" in timeout={{ enter: 500 }}>
				<div className={classes.responsiveSocialIcons}>
					<div style={{ marginBottom: 20 }}>
						<a
							href={socialMediaPages.facebook}
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaFacebook size="1.2rem" color="#fff" />
						</a>
					</div>
					<div style={{ marginBottom: 20 }}>
						<a
							href={socialMediaPages.twitter}
							target="_blank"
							rel="noopener noreferrer"
						>
							<AiFillTwitterCircle size="1.2rem" color="#fff" />
						</a>
					</div>
					<div style={{ marginBottom: 20 }}>
						<a
							href={socialMediaPages.linkedIn}
							target="_blank"
							rel="noopener noreferrer"
						>
							<AiFillLinkedin size="1.2rem" color="#fff" />
						</a>
					</div>
					<div style={{ marginBottom: 20 }}>
						<a href={socialMediaPages.xing} target="_blank" rel="noopener noreferrer">
							<FaXingSquare size="1.2rem" color="#fff" />
						</a>
					</div>
					<div style={{ marginBottom: 20 }}>
						<a
							href={socialMediaPages.instagram}
							target="_blank"
							rel="noopener noreferrer"
						>
							<AiOutlineInstagram size="1.2rem" color="#fff" />
						</a>
					</div>
				</div>
			</Slide>
		</div>
	)
}

export default Hero
