import { useState } from "react"
import Link from "components/i18n/Link"
import { Container, Grid, Box } from "@mui/material"

import { makeStyles } from "makeStyles"

import Image, { StaticImageData } from "next/image"
import ItemsCarousel from "react-items-carousel"

import { BsArrowRight, BsArrowLeft } from "react-icons/bs"
import { Category } from "../../../common/typings/Category"
import { SeriesPreview } from "utils/commerceTools/fetchCategory"

import useWindowSize from "@secureo/common/hooks/useWindowSize"

const useStyles = makeStyles()(() => ({
	arrowButtonLeft: {
		position: "absolute",
		bottom: -50,
		left: 0,
		padding: 5,
		outline: "none",
		background: "none",
		border: "none",
		cursor: "pointer",
	},
	arrowButtonRight: {
		position: "absolute",
		bottom: -50,
		right: "100%",
		padding: 5,
		outline: "none",
		background: "none",
		border: "none",
		cursor: "pointer",
	},
	wrapper: {
		height: "800px",
		overflow: "hidden",
		position: "relative",
		"@media only screen and (max-width: 960px)": {
			overflow: "hidden",
			// background: "#082129",
		},
	},
	background: {
		width: "100%",
		height: "800px",
		margin: "0 0 0 -20px",
		transform: "skew(20deg)",
		background: "#082129",
		overflow: "hidden",
		position: "absolute",
		top: 0,
		left: "38%",
		zIndex: 0,
		"@media only screen and (max-width: 960px)": {
			display: "none",
		},
	},
	block: {
		color: "white",
		padding: "120px 0px",
		"@media only screen and (max-width: 960px)": {
			padding: "50px 0px",
		},
	},
	card: {
		width: "100%",
		borderRadius: 5,
		background: "#042C33",
		boxShadow: "0 10px 12px rgba(0, 0, 0, 0.10)",
		minHeight: 125,
		"&:hover": {
			boxShadow: "0 10px 12px rgba(0, 0, 0, 0.30)",
			cursor: "pointer",
		},
	},
	cardsAlignment: {
		marginTop: 50,
		"@media only screen and (max-width: 960px)": {
			marginTop: 30,
		},
	},
	backgroundImage: {
		position: "absolute",
		top: 0,
		left: 0,
		height: "800px",
		width: "57vw",
		zIndex: -1,
		"@media only screen and (max-width: 960px)": {
			width: "100vw",
			opacity: 0.9,
			filter: "brightness(0.3) grayscale(10%)",
		},
	},
}))

const CategorySection = ({ category, backgroundImage }: Props) => {
	const { name, slug, series = [], shortDescription } = category
	const { classes } = useStyles()

	const [activeItemIndex, setActiveItemIndex] = useState(0)
	const chevronWidth = 0
	const { innerWidth } = useWindowSize()

	const isDesktop = innerWidth < 960
	const isTablet = innerWidth < 600

	const slideCount = !isDesktop ? 4 : isTablet ? 1 : 3

	// TODO: get category image, headline + subheadline from CT category
	return (
		<div className={classes.wrapper}>
			<div className={classes.backgroundImage}>
				<Image
					alt="Kontakt"
					src={backgroundImage}
					quality={100}
					fill
					sizes="100vw"
					style={{
						objectFit: "cover",
					}}
				/>
			</div>
			<Container>
				<Box className={classes.block}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={5}>
							{null}
						</Grid>
						<Grid item xs={12} md={7}>
							<Box style={{ color: "white", zIndex: 2, position: "relative" }}>
								<p style={{ color: "#009CAC", marginBottom: 0, fontSize: 18 }}>
									<strong>{name}</strong>
								</p>
								<h3
									style={{
										marginTop: 0,
										maxWidth: 700,
										lineHeight: 1.3,
										fontSize: 30,
									}}
								>
									Geprüfte und zertifizierte {name}
								</h3>
								<p style={{ maxWidth: 500 }}>
									{shortDescription ||
										`Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
									diam nonumy eirmod tempor invidunt ut`}
								</p>

								<div className={classes.cardsAlignment}>
									<ItemsCarousel
										requestToChangeActive={setActiveItemIndex}
										activeItemIndex={activeItemIndex}
										numberOfCards={slideCount}
										gutter={10}
										leftChevron={
											<button className={classes.arrowButtonLeft}>
												<BsArrowLeft size="1.8rem" color="#fff" />
											</button>
										}
										rightChevron={
											<button className={classes.arrowButtonRight}>
												<BsArrowRight size="1.8rem" color="#fff" />
											</button>
										}
										chevronWidth={chevronWidth}
										infiniteLoop={false}
										slidesToScroll={1}
										showSlither={true}
									>
										{series.map((series) => {
											const { name, slug } = series
											return (
												<Link key={slug} href={`/s/${slug}`}>
													<div className={classes.card}>
														<Box p={2}>
															<div
																style={{
																	minHeight: 60,
																}}
															>
																<strong>{name} Serie</strong>
															</div>
															<div
																style={{
																	display: "flex",
																	alignItems: "center",
																	alignSelf: "flex-end",
																	justifyContent: "space-between",
																	fontSize: 14,
																	color: "whitesmoke",
																}}
															>
																<div>Entdecken</div>
																<BsArrowRight
																	size="1.3rem"
																	color="#fff"
																/>
															</div>
														</Box>
													</div>
												</Link>
											)
										})}
										<Link href={`/c/${slug}`}>
											<div
												className={classes.card}
												style={{ background: "none", boxShadow: "none" }}
											>
												<div
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent: "space-between",
														marginTop: 20,
														fontSize: 14,
														color: "whitesmoke",
													}}
												>
													<span>Alle anzeigen</span>
													<BsArrowRight size="1.3rem" color="#fff" />
												</div>
											</div>
										</Link>
									</ItemsCarousel>
								</div>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<div className={classes.background} />
			</Container>
		</div>
	)
}

interface Props {
	category: Category & { series?: SeriesPreview[] }
	backgroundImage: StaticImageData
}

export default CategorySection
