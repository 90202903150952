import { Box, Container, Grid } from "@mui/material"
import { makeStyles } from "makeStyles"
import Link from "components/i18n/Link"

import { Category } from "@secureo/common/typings/Category"
import Image from "next/image"

import Feuerschutztresore from "/images/feuerschutztresore.png"
import Wandtresore from "/images/wandtresore-category.png"
import Hotelsafes from "/images/hotelsafes.png"

const useStyles = makeStyles()(() => ({
	grid: {
		height: 700,
		width: "100%",
		boxShadow: "0 10px 12px rgba(0, 0, 0, 0.10)",
		background: "white",
		textAlign: "center",
		position: "relative",
		"&:hover": {
			boxShadow: "0 10px 12px rgba(0, 0, 0, 0.30)",
			cursor: "pointer",
		},
		"@media only screen and (max-width: 960px)": {
			height: 260,
		},
	},
	headline: {
		padding: 50,
		color: "white",
		fontWeight: 800,
		letterSpacing: "0.08rem",
		fontSize: "1.2rem",
		position: "absolute",
		bottom: 90,
		textShadow: "1px 1px 2px rgba(0, 0, 0, 0.7)",
	},
	text: {
		padding: 30,
		color: "white",
		position: "absolute",
		bottom: 30,
		textShadow: "1px 1px 2px rgba(0, 0, 0, 0.7)",
	},
	backgroundImage: {
		textAlign: "center",
		borderRadius: 5,
		height: 800,
		"@media only screen and (max-width: 600px)": {
			height: 350,
		},
	},
}))

const categoryMetadata = [
	{
		image: Feuerschutztresore,
		headline: "Perfekt geschützt vor Flammen & Hitze",
		text: "Egal ob sensible Dokumente, Verträge oder wertvolle Familienfotos und Erinnerungen",
	},
	{
		image: Wandtresore,
		headline: "Fest im Mauerwerk verankert",
		text: "Hoher und gleichzeitig preiswerter Schutz für Schmuck, Bargeld & Wertsachen",
	},
	{
		image: Hotelsafes,
		headline: "Komfort & Sicherheit im Hotelzimmer",
		text: "Entdecken Sie unsere hochwertigen Hotelzimmertresore",
	},
]

const Explore = ({ categories }: Props) => {
	const { classes } = useStyles()

	return (
		<Box style={{ background: "#182F37", padding: "100px 0px", margin: "0px !important" }}>
			<Container>
				<Grid container spacing={3}>
					{categories.map((category, i) => {
						const { slug } = category
						const { headline, text, image } = categoryMetadata[i]
						return (
							<Grid item xs={12} sm={4} key={slug}>
								<Link href={`/c/${slug}`}>
									<div style={{ position: "relative" }}>
										<div className={classes.backgroundImage}>
											<Image
												alt={headline}
												title={headline}
												src={image}
												quality={100}
												fill
												sizes="100vw"
												style={{
													objectFit: "cover",
												}}
											/>
											<div className={classes.headline}>{headline}</div>
											<div className={classes.text}>{text}</div>
										</div>
									</div>
								</Link>
							</Grid>
						)
					})}
				</Grid>
			</Container>
		</Box>
	)
}

interface Props {
	categories: Category[]
}

export default Explore
