import { Container, Box, Grid } from "@mui/material"
import { FiTruck } from "react-icons/fi"
import { RiShieldCheckLine } from "react-icons/ri"
import { FiShoppingBag } from "react-icons/fi"

import Customers from "components/Customers"

import formatPrice from "@secureo/common/utils/forex/formatPrice"

import { flatFeesByCurrencyCode } from "@secureo/common/config/shippingZonesAndCosts"

const facts = [
	{
		icon: <FiTruck size="3.5rem" color="#009CAC" />,
		label: "Gratis Lieferung",
		text: `Die Zustellung ist versandkostenfrei ab einem Warenwert von ${formatPrice(
			flatFeesByCurrencyCode.EUR.flatFeeForSmallAmountThresholdGrossCents,
			"de-DE",
		)}. Produkte über einem Gewicht von 35 kg werden mit Spedition verschickt und die Lieferung erfolgt Frei Bordsteinkante. Optional bieten wir auch eine aufpreispflichtige Vertragung an den Verwendungsort mit oder ohne fachmännischer Montage an.`,
	},
	{
		icon: <RiShieldCheckLine size="3.5rem" color="#009CAC" />,
		label: "Große Produktauswahl",
		text: "Egal ob für Gewerbebetriebe oder im Privatbereich – Tresore und Waffenschränke von Secureo stehen für allerhöchste Qualitäts- und Sicherheitsstandards. Wir stehen nicht nur für hochwertige Tresore, sondern auch für moderne Sicherheitstechnik.",
	},
	{
		icon: <FiShoppingBag size="3.5rem" color="#009CAC" />,
		label: "Sicher online einkaufen",
		text: "Auch die Online-Sicherheit liegt uns am Herzen. Darum setzen wir modernsten und aktuellsten Verschlüsselungstechniken ein. Ihre Daten gehören Ihnen und so soll es auch bleiben!Selbstverständlich werden sämtliche Daten vertraulich gehandhabt und unterliegen der DSGVO.",
	},
]

const Hero = () => {
	return (
		<div
			style={{
				background: "#182F37",
			}}
		>
			<Container>
				<div style={{ color: "white", padding: "100px 0px" }}>
					<p style={{ color: "#009CAC", marginBottom: 0, fontSize: 18 }}>
						<strong>Vorteile</strong>
					</p>
					<h2
						style={{
							marginTop: 0,
							maxWidth: 700,
							lineHeight: 1.3,
							fontSize: 38,
						}}
					>
						Ihre Vorteile bei uns
					</h2>
					<p style={{ maxWidth: 700, lineHeight: 1.3, opacity: 0.7, marginBottom: 40 }}>
						Profitieren Sie von zahlreichen Vorteilen bei Produkten von Secureo. Wir
						beraten Sie nicht nur gerne, sonder sind auch nach dem Kauf immer für sie
						da.
					</p>
					<Grid container spacing={3}>
						{facts.map((fact) => {
							return (
								<Grid item xs={12} sm={6} md={4} key={fact.label}>
									<Box my={2}>{fact.icon}</Box>
									<div style={{ color: "white" }}>
										<div style={{ marginBottom: 15 }} className="h2">
											{fact.label}
										</div>
										<div>{fact.text}</div>
									</div>
								</Grid>
							)
						})}
					</Grid>

					<Customers hasDarkBackground={true} />
				</div>
			</Container>
		</div>
	)
}

export default Hero
